import React, { useState, useEffect } from 'react';
import style from './JobHistory.module.css';
import { executionErrorOccured, subHeader, updateLoading, onLogOut } from '../../../redux/slices/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
	Body,
	WizardFooter,
	Button,
	Checkbox,
	Card,
	useSnackbar,
	BottomSheet,
	Modal,
	Divider,
	ButtonGroup,
	Spinner
} from '@walmart-web/livingdesign-components';
import { useTranslation } from 'react-i18next';
import JobExpModal from './JobExpModal';
import LicenseAndCertificationModal from './LicenseAndCertificationModal';
import {
	updateJobIndex,
	updateEditJob,
	updateLicenseIndex,
	updateAllEmployers,
	updateJobHistory,
	updateLicense,
	resetJobHistory,
	resetLicenseHistory,
	resetAllEmployers
} from '../../../redux/JobHistory/slice';
import { getPersonalInfo } from '../../../redux/AboutYou/request';
import { descriptions, issuedby, states, allStates, countries, GTM, EMPL_HIST_COMPLETED } from '../../../config/const';
import {
	getEmplHistory,
	getCertificateLicenses,
	deleteJobEntry,
	deleteCertificateLicenses,
} from '../../../redux/JobHistory/request';
import { saveHcApplicationStatus } from 'redux/Account/request';
import { useNavigate } from 'react-router';
import { callHiringHelper } from '../../../redux/HiringHelper/request';
import { ReactComponent as JobHistorySvg } from '../../../assets/svg/jobHistory.svg';
import * as moment from 'moment';
import 'moment/min/locales.min';
import { sendGtmEvent } from '../../../utils/GoogleTagManagerUtils';
import { useDisplay } from 'utils/useDisplay';
import AlertDialog from 'components/AlertDialog';
import {checkRehire} from '../../../redux/AboutYou/request';
import ExitModal from './../AboutYou/AboutYouPersonalInfo/ExitModal';

const JobHistory = (props) => {
	const global = useSelector((state) => state.global);
	const account = useSelector((state) => state.account);
	const aboutYou = useSelector((state) => state.aboutYou.applInfo);
	const navigate = useNavigate();
	const countryCode = global.countryCode === 'PR' ? 'PR' : 'US';
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { addSnack } = useSnackbar();
	const [isOpenExp, setisOpenExp] = useState(false);
	const [isOpenLic, setIsOpenLic] = useState(false);
	const [licenseList, setLicenseList] = useState([]);
	const [jobList, setJobList] = useState([]);
	const jobHistory = useSelector((state) => state.jobHistory);
	const [editJobData, setEditJobData] = useState(false);
	const [editLicenseData, setEditLicenseData] = useState(false);
	const { isPortrait } = useDisplay();
	const [checkBox1, setCheckBox1] = useState(true);
	const [checkBox2, setCheckBox2] = useState(false);
	const [remove, setRemove] = useState(false);
	const [removeJobIndex, setRemoveJobIndex] = useState(0);
	const [removeLicenseIndex, setRemoveLicenseIndex] = useState(0);
	const [removeType, setRemoveType] = useState('');
	const Experience = useSelector((state) => state.Experience);
	const [jobExp, setJobExp] = useState(false);
	const [refreshEmpHistFromDB, setRefreshEmpHistFromDB] = useState(false);
	const [refreshLicenseFromDB, setRefreshLicenseFromDB] = useState(false);
	const [prevEmpConfirmationRequired, setPrevEmpConfirmationRequired] = useState(false);
	const [exitText, setExitText] = useState('');
	const [exit, setExit] = useState(false);
	const [hasDenied, setHasDenied] = useState(false);

	const [potentialMatchStoreNbr , setPotentialMatchStoreNbr] = useState(account?.data?.auth?.potentialMatchStoreNbr);
	
	moment.locale(global.language === 'ES' ? 'es' : 'en');
	const jobHistoryRemove = 'jobHistory.remove';

	useEffect(() => {
		dispatch(
			subHeader({
				title: t('welcomePage.jobHistory'),
				routeTo: 'job-history',
				display: 'true'
			})
		);

		const requestInput = {
			applicantId: global.applicantId,
			emailId: global.emailId
		};
		if (Experience.experience.hcTestResponse[0].applicantId !== '') {
			Experience.experience.hcTestResponse.forEach((obj) => {
				if (obj.answerNbr > 76) {
					setJobExp(true);
				}
			});
		} else {
			dispatch(updateLoading(true));
			dispatch(getPersonalInfo(requestInput))
				.then((res) => {
					const data = res.payload;
					if (Object.keys(data).length !== 0) {
						let hasExperience = false;
						data.hcTestResponse.forEach((obj) => {
							if (obj.questionNbr === 31 && obj.pretestCode === 20 && obj.revisionNbr === 3) {
								if (obj.answerNbr > 76) {
									hasExperience = true;
								}
							}
							if (obj.questionNbr === 30 && obj.pretestCode === 20 && obj.revisionNbr === 3) {
								if (obj.answerNbr > 76) {
									hasExperience = true;
								}
							}
							if (obj.questionNbr === 41 && obj.pretestCode === 20 && obj.revisionNbr === 3) {
								if (obj.answerNbr > 76) {
									hasExperience = true;
								}
							}
							if (obj.questionNbr === 43 && obj.pretestCode === 20 && obj.revisionNbr === 3) {
								if (obj.answerNbr > 76) {
									hasExperience = true;
								}
							}
						});
						if (hasExperience) {
							setJobExp(true);
							setCheckBox1(false);
						}
					}
					dispatch(updateLoading(false));
				})
				.catch((err) => {
					dispatch(executionErrorOccured());
				});
		}

		getJobAndLicenseDetails();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (refreshEmpHistFromDB) {
			getJobHistoryDetails();
			setRefreshEmpHistFromDB(false);
		}
		if (refreshLicenseFromDB) {
			getLicensesDetails();
			setRefreshLicenseFromDB(false);
		}
		// eslint-disable-next-line
	}, [refreshEmpHistFromDB, refreshLicenseFromDB]);

	const getJobHistoryDetails = () => {
		const requestInput = {
			applicantId: global.applicantId
		};
		dispatch(resetJobHistory({ allEmployers: [] }));
		dispatch(resetAllEmployers({ allEmployers: [] }));
		dispatch(getEmplHistory(requestInput))
			.then((res) => {
				const data = res.payload;
				dispatch(updateAllEmployers({ allEmployers: data.allEmployers }));
				let empHistList = [];
				if (data.allEmployers.length !== 0) {
					data.allEmployers.forEach((obj) => {
						const list = {
							companyName: obj.company_name,
							position: obj.allPositions[0].job_position_desc,
							industry: obj.industry_catg_code,
							category: obj.allPositions[0].maj_work_grp_code,
							subCategory: obj.allPositions[0].min_work_grp_code,
							startDate: obj.allPositions[0].pos_start_date,
							endDate: obj.allPositions[0].pos_end_date,
							reason: obj.left_reason_text,
							employer_seq_nbr: obj.employer_seq_nbr
						};
						dispatch(updateJobHistory({ jobDetail: list }));
						empHistList.push(list);
					});
				}
				setJobList(empHistList);
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	const getLicensesDetails = () => {
		const requestInput = {
			applicantId: global.applicantId
		};
		dispatch(resetLicenseHistory({ license: [] }));
		dispatch(getCertificateLicenses(requestInput))
			.then((res) => {
				const data = res.payload;
				let certLicenseList = [];
				if (data.certificationLicenses.length !== 0) {
					data.certificationLicenses.forEach((obj) => {
						const license = {
							issuedType: obj.certIssuedBy,
							description: obj.certDescriptionCode,
							certYearIssued: obj.certYearIssued,
							certYearExpires: obj.certYearExpires,
							selectedCountry: obj.certIssuedCountry,
							selectedState: obj.certIssuedState,
							oldCertDescriptionCode: obj.oldCertDescriptionCode,
							oldCertIssuedBy: obj.oldCertIssuedBy,
							oldCertIssuedCountry: obj.oldCertIssuedCountry,
							oldCertIssuedState: obj.oldCertIssuedState,
							oldCertYearIssued: obj.oldCertYearIssued
						};
						dispatch(updateLicense({ license: license }));
						certLicenseList.push(license);
					});
				}
				setLicenseList(certLicenseList);
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};
	const getJobAndLicenseDetails = () => {
		dispatch(updateLoading(true));
		getJobHistoryDetails();
		getLicensesDetails();
		dispatch(updateLoading(false));
	};

	const handleExpModal = (value) => {
		setEditJobData(false);
		dispatch(updateEditJob({ editJob: false }));
		setisOpenExp(value);
	};

	const handleLicModal = (value) => {
		setEditLicenseData(false);
		setIsOpenLic(value);
	};

	const handleAddjob = () => {
		setEditJobData(false);
		setisOpenExp(false);
		setRefreshEmpHistFromDB(true);
		jobSnackBar();
	};

	const handleEditJob = (jobDetail) => {
		setEditJobData(false);
		setisOpenExp(false);
		setRefreshEmpHistFromDB(true);
	};

	const handleEditLicense = (license) => {
		const list = {
			issuedType: license.issuedType,
			description: license.description,
			certYearIssued: license.certYearIssued,
			certYearExpires: license.certYearExpires,
			selectedCountry: license.selectedCountry,
			selectedState: license.selectedState
		};
		if (list.issuedType !== '') {
			setLicenseList((licenseList) => [...licenseList, list]);
		}
		setEditLicenseData(false);
		setIsOpenLic(false);
		setRefreshLicenseFromDB(true);
	};

	const handleAddLicense = (license) => {
		const list = {
			issuedType: license.issuedType,
			description: license.description,
			certYearIssued: license.certYearIssued,
			certYearExpires: license.certYearExpires,
			selectedCountry: license.selectedCountry,
			selectedState: license.selectedState
		};
		if (list.issuedType !== '') {
			setLicenseList((licenseList) => [...licenseList, list]);
		}
		setEditLicenseData(false);
		setIsOpenLic(false);
		setRefreshLicenseFromDB(true);
		LicenseSnackBar();
	};

	const editJob = (index) => {
		dispatch(updateJobIndex({ jobIndex: index }));
		dispatch(updateEditJob({ editJob: true }));
		setEditJobData(true);
		setisOpenExp(true);
	};

	const editLicense = (index) => {
		dispatch(updateLicenseIndex({ licenseIndex: index }));
		setEditLicenseData(true);
		setIsOpenLic(true);
	};

	const removeJob = (index) => {
		const deleteJob = jobList[index];
		const request = {
			allEmployers: [
				{
					employer_seq_nbr: deleteJob.employer_seq_nbr,
					industry_catg_code: deleteJob.industry,
					last_pay_rate_amt: '',
					company_name: deleteJob.companyName,
					old_company_name: deleteJob.companyName,
					address_line_1: '',
					address_line_2: null,
					city_name: '',
					state_prov_code: '',
					country_code: countryCode,
					postal_code: '',
					phone_nbr: '',
					supvr_first_name: null,
					supvr_last_name: null,
					contact_allow_ind: 'Y',
					left_reason_text: '',
					empl_start_date: null,
					empl_end_date: null,
					positionHistory: {
						job_postn_seq_nbr: '1',
						job_position_desc: deleteJob.position,
						old_job_position_desc: deleteJob.position,
						pos_start_date: deleteJob.startDate,
						pos_end_date: deleteJob.endDate,
						maj_work_grp_code: deleteJob.category,
						min_work_grp_code: deleteJob.subCategory
					},
					allPositions: [
						{
							job_postn_seq_nbr: '1',
							job_position_desc: deleteJob.position,
							old_job_position_desc: deleteJob.position,
							pos_start_date: deleteJob.startDate,
							pos_end_date: deleteJob.endDate,
							maj_work_grp_code: deleteJob.category,
							min_work_grp_code: deleteJob.subCategory
						}
					]
				}
			],
			applicant_id: global.applicantId
		};

		dispatch(updateLoading(true));
		dispatch(deleteJobEntry(request))
			.then((res) => {
				const data = res.payload;
				if (data === true) {
					if (account?.data?.auth?.stepNbr >= 850) {
						dispatch(callHiringHelper(global.applicantId));
					}
					setRefreshEmpHistFromDB(true);
					setRemove(false);
				}
				dispatch(updateLoading(false));
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	const getDescriptionName = (value, country) => {
		let descriptionList;
		if (country === 'US') {
			descriptionList = descriptions[101];
		} else {
			descriptionList = descriptions[102];
		}
		let val = '';
		if (value === '') {
			return '';
		} else {
			descriptionList.forEach((obj) => {
				if (obj.value === value) {
					val = obj.viewValue;
				}
				return val;
			});
			return val;
		}
	};

	const getIssuedByDesc = (value, country) => {
		let issuedTypeList;
		if (country === 'US') {
			issuedTypeList = issuedby[101];
		} else {
			issuedTypeList = issuedby[102];
		}
		let val = '';
		if (value === '') {
			return '';
		} else {
			issuedTypeList.forEach((obj) => {
				if (obj.value === value) {
					val = obj.viewValue;
				}
				return val;
			});
			return val;
		}
	};

	const countryDetail = (code, country) => {
		let countrytList;
		if (country === 'US') {
			countrytList = countries[101];
		} else {
			countrytList = countries[102];
		}
		let val = '';
		if (code === '') {
			return '';
		} else {
			countrytList.forEach((obj) => {
				if (obj.value === code) {
					val = obj.viewValue;
				}
				return val;
			});
			return val;
		}
	};

	const stateDetail = (code, country, language) => {
		let stateList;
		if (country === 'US') {
			stateList = allStates;
		} else {
			if (language === 'US') {
				stateList = states[101];
			} else {
				stateList = states[102];
			}
		}
		let val = '';
		if (code === '') {
			return '';
		} else {
			stateList.forEach((obj) => {
				if (obj.value === code) {
					val = obj.viewValue;
				}
				return val;
			});
			return val;
		}
	};

	const getLicenseRequest = (action, index) => {
		const license = jobHistory.license[index];
		const request = {
			applSeqNbr: 0,
			applicantId: global.applicantId,
			certDescriptionCode: license.description,
			certDescriptionName: getDescriptionName(license.description, 'US'),
			certDescriptionSpanishName: getDescriptionName(license.description, 'PR'),
			certIssuedBy: license.issuedType,
			certIssuedByDesc: getIssuedByDesc(license.issuedType, 'US'),
			certIssuedBySpanishDesc: getIssuedByDesc(license.issuedType, 'PR'),
			certIssuedCountry: license.selectedCountry,
			certIssuedCountryDesc: countryDetail(license.selectedCountry, 'US'),
			certIssuedCountrySpanishDesc: countryDetail(license.selectedCountry, 'PR'),
			certIssuedState: license.selectedState,
			certIssuedStateDesc: stateDetail(license.selectedState, license.selectedCountry, 'US'),
			certIssuedStateSpanishDesc: stateDetail(license.selectedState, license.selectedCountry, 'PR'),
			certYearExpires: license.certYearExpires,
			certYearIssued: license.certYearIssued,
			certifLiceStatus: false,
			fromCTS: false,
			oldCertDescriptionCode: null,
			oldCertIssuedBy: null,
			oldCertIssuedCountry: null,
			oldCertIssuedState: null,
			oldCertYearIssued: null,
			saveMode: 'delete'
		};

		if (action === 'edit') {
			request.saveMode = 'edit';
		}

		return request;
	};

	const removeLicense = (index) => {
		const request = getLicenseRequest('delete', index);
		dispatch(updateLoading(true));
		dispatch(deleteCertificateLicenses(request))
			.then((res) => {
				const data = res.payload;
				if (data === true) {
					setRefreshLicenseFromDB(true);
					setRemove(false);
				}
				dispatch(updateLoading(false));
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	};

	const handleAddnewExp = () => {
		setEditJobData(false);
		setisOpenExp(true);
	};

	const handleAddNewLic = () => {
		setEditLicenseData(false);
		setIsOpenLic(true);
	};

	const handleCheckbox1 = () => {
		setCheckBox1(!checkBox1);
	};

	const handleCheckbox2 = () => {
		setCheckBox2(!checkBox2);
	};

	const jobSnackBar = () => {
		return (
			<div>
				{addSnack({
					message: t('jobHistory.jobAdded')
				})}
			</div>
		);
	};

	const LicenseSnackBar = () => {
		return (
			<div>
				{addSnack({
					message: t('jobHistory.licenseAdded')
				})}
			</div>
		);
	};

	const removeJobOrLicense = (value, index) => {
		setRemove(true);
		if (value === 'job') {
			setRemoveType('job');
			setRemoveJobIndex(index);
		} else if (value === 'license') {
			setRemoveType('license');
			setRemoveLicenseIndex(index);
		}
	};

	const disableButton = () => {
		if (!jobExp && jobList.length === 0) {
			return !checkBox1;
		} else if ((jobExp && jobList.length !== 0) || jobList.length !== 0) {
			return !checkBox2;
		} else if (jobExp && jobList.length === 0) {
			return true;
		}
	};

	const getIssuedType = (item) => {
		let val;
		if (global.language === 'ES') {
			issuedby[102].forEach((obj) => {
				if (obj.value === item.issuedType) {
					val = obj.viewValue;
				}
			});
		} else {
			issuedby[101].forEach((obj) => {
				if (obj.value === item.issuedType) {
					val = obj.viewValue;
				}
			});
		}
		return val;
	};

	const getDescriptionDisplay = (item) => {
		let val;
		if (global.language === 'EN') {
			descriptions[101].forEach((obj) => {
				if (obj.value === item.description) {
					val = obj.viewValue;
				}
			});
		} else {
			descriptions[102].forEach((obj) => {
				if (obj.value === item.description) {
					val = obj.viewValue;
				}
			});
		}
		return val;
	};

	const updateHcApplicationStatus = () => {
		const req = {
			statusCode: EMPL_HIST_COMPLETED,
			applicantId: global.applicantId
		}
		dispatch(updateLoading(true));
		dispatch(saveHcApplicationStatus(req))
			.then((res) => {
				dispatch(updateLoading(false));
				if (res?.payload?.prevEmpConfirmationRequired) {
					setPrevEmpConfirmationRequired(true);
					setPotentialMatchStoreNbr(res?.payload?.potentialMatchStoreNbr)
				}
				else {
					if (account.data.auth.onlyPrJobsPreferred) {
						navigate('../minimum-qualifications');
					}
					else {
						navigate('../wotc-questionnaire');
					}
				} 
			})
			.catch((err) => {
				dispatch(executionErrorOccured());
			});
	}
	const handleEmplVerfPopUp = (action) => {
		const requestInput = {
			formerAssociate: action == "confirm" ? "Y" : "N",
			applicantId: global.applicantId,
			lastName: aboutYou.lastName,
			partialDob: aboutYou.partialDob,
			partSsnNbr: aboutYou.partSsnNbr,
			zipCode: aboutYou.zipCode,
			applStatusCode: 0,
			fromExpPage : true
		};
		dispatch(checkRehire(requestInput))
		.then((res) => {
						if (res.payload.responseCode === '201' || res.payload.responseCode === '200') {
							if (account.data.auth.onlyPrJobsPreferred) {
								navigate('../minimum-qualifications');
							}
							else {
								navigate('../wotc-questionnaire');
							};
						} else if (res.payload.responseCode === '300') {
							const responseText = res.payload.responseMessage.split('#@#');
							if (global.language === 'ES') {
								setExitText(responseText[2]);
							} else {
								setExitText(responseText[1]);
							}
							setExit(true);
						}
		
						dispatch(updateLoading(false));
					})
					.catch((err) => {
						dispatch(executionErrorOccured());
					});
	}

	
	const handleExit = (value) => {
		setExit(value);
		dispatch(onLogOut());
	};

	const handlePopUpSubmit = (action) => {
		
		setPrevEmpConfirmationRequired(false);
		dispatch(updateLoading(true));
		setHasDenied(action === "deny");
		handleEmplVerfPopUp(action);

	}


	return (
		<div className={style.main}>
			{exit && <ExitModal exit={exit} handleExit={handleExit} exitText={exitText} />}
			{prevEmpConfirmationRequired 
				&& potentialMatchStoreNbr !== 0 
				&& !hasDenied 
				&&
				< Modal
					actions={
						<ButtonGroup>
							<Button variant="primary" onClick={() => handlePopUpSubmit("deny")} >{t('general.deny')}</Button>
							<Button variant="primary" onClick={() => handlePopUpSubmit("confirm")} >{t('general.confirm')}</Button>
						</ButtonGroup>
					}
					// closeButtonProps={{ "aria-label": "Close Modal" }}
					isOpen={prevEmpConfirmationRequired}
					onClose={() => {setPrevEmpConfirmationRequired(false)}}
					title={t('aboutYouPersonalInfo.preEmployment')}
				>
					{t('aboutYouPersonalInfo.previousRecord')}<br /><br />
					{t('aboutYouPersonalInfo.walmartLocation')}
					{potentialMatchStoreNbr}<br /><br />
					{t('aboutYouPersonalInfo.confirmRecord')}
				</Modal>}
				{global.loading && <Spinner color="white" size="large" />}
			<div className={style.blueHead}></div>
			<JobHistorySvg className={style.experienceImg} aria-hidden={true} />

			<div className={style.container}>
				<div className={style.title}>
					<Body as="p" size="large" weight={700}>
						{t('jobHistory.title')}
						{jobExp && !jobList.length && (
							<Body
								as="span"
								size="small"
								weight={400}
								UNSAFE_style={{ color: '#DE1C24', paddingLeft: '16px' }}>
								{t('jobHistory.required')}
							</Body>
						)}
					</Body>
				</div>
				<div className={style.jobHistoryText}>
					<Body as="p" size="small" weight={400} UNSAFE_style={{ color: '#707070' }}>
						{t('jobHistory.historyText1')}
					</Body>
					<br />
					<Body as="p" size="small" weight={400} UNSAFE_style={{ color: '#707070' }}>
						{t('jobHistory.historyText2')}
					</Body>
				</div>
				{jobList.length !== 0
					? jobList.map((item, index) => {
							return (
								<div style={{ margin: '16px' }} key={index}>
									<Card size="small" UNSAFE_className={style.card}>
										<div style={{ width: '100%' }}>
											<div style={{ float: 'left' }}>
												<Body as="p" size="medium" weight={700}>
													{item.position}
												</Body>
											</div>
											<div className={isPortrait ? style.mobileEdit : style.desktopEdit}>
												<div style={{ float: 'left' }}>
													<Button
														variant="tertiary"
														UNSAFE_style={{ padding: 0, height: 'auto' }}
														onClick={() => editJob(index)}>
														{t('jobHistory.edit')}
													</Button>
												</div>

												<div style={{ float: 'right' }}>
													<Button
														variant="tertiary"
														UNSAFE_style={{ padding: 0, height: 'auto' }}
														onClick={() => removeJobOrLicense('job', index)}>
														{t(jobHistoryRemove)}
													</Button>
												</div>
											</div>
										</div>
										<div
											style={{
												display: 'flex',
												flexDirection: 'column',
												gap: '12px',
												width: '100%'
											}}>
											<div>
												<img
													src={require('../../../assets/images/companyIcon.png')}
													alt="Company icon"
												/>
												<div style={{ width: '90%', display: 'inline-block', paddingLeft: '10px' }}>
													<Body
														as="p"
														size="small"
														weight={400}
														UNSAFE_style={{ color: '#2E2F32' }}>
														{item.companyName}
													</Body>
												</div>
											</div>
											<div>
												<img
													src={require('../../../assets/images/calenderIcon.png')}
													alt="Work duration"
												/>
												<div style={{ width: '90%', display: 'inline-block', paddingLeft: '10px' }}>
													<Body
														as="p"
														size="small"
														weight={400}
														UNSAFE_style={{ color: '#2E2F32' }}>
														{moment(item.startDate, 'MM/YYYY', true).format('MMMM YYYY')}
														{' - '}
														{moment(item.endDate, 'MM/YYYY', true).isValid()
															? moment(item.endDate, 'MM/YYYY', true).format('MMMM YYYY')
															: moment().format('MMMM YYYY')}
													</Body>
												</div>
											</div>
										</div>
									</Card>
									{!isPortrait && <Divider />}
								</div>
							);
					  })
					: ''}

				<div style={{ padding: '16px' }}>
					<Button size="medium" variant="secondary" onClick={handleAddnewExp}>
						{t('jobHistory.add')}
					</Button>
				</div>
				<JobExpModal
					isOpenExp={isOpenExp}
					handleExpModal={handleExpModal}
					handleAddjob={handleAddjob}
					handleEditJob={handleEditJob}
					jobList={jobList}
					editJobData={editJobData}
				/>

				<LicenseAndCertificationModal
					isOpenLic={isOpenLic}
					handleLicModal={handleLicModal}
					handleAddLicense={handleAddLicense}
					licenseList={licenseList}
					editLicenseData={editLicenseData}
					handleEditLicense={handleEditLicense}
					getDescriptionName={getDescriptionName}
					getIssuedByDesc={getIssuedByDesc}
					countryDetail={countryDetail}
					stateDetail={stateDetail}
				/>

				{!isPortrait && (
					<Modal
						isOpen={remove}
						onClose={() => setRemove(false)}
						title={
							removeType === 'job' ? t('jobHistory.removeJob') : t('jobHistory.removeLicense')
						}>
						<div style={{ paddingBottom: '16px' }}>
							<Body as="p" size="medium" weight={400}>
								{removeType === 'job'
									? t('jobHistory.removeJobMessage')
									: t('jobHistory.removeLicenseMessage')}
							</Body>
						</div>
						<Divider />
						<div style={{ paddingTop: '16px', width: '100%' }}>
							<Button
								size="medium"
								variant="primary"
								UNSAFE_style={{ width: '100%' }}
								onClick={
									removeType === 'job'
										? () => removeJob(removeJobIndex)
										: () => removeLicense(removeLicenseIndex)
								}>
								{t(jobHistoryRemove)}
							</Button>
						</div>
					</Modal>
				)}

				{isPortrait && (
					<BottomSheet
						isOpen={remove}
						onClose={() => setRemove(false)}
						title={
							removeType === 'job' ? t('jobHistory.removeJob') : t('jobHistory.removeLicense')
						}>
						<div style={{ paddingBottom: '16px' }}>
							<Body as="p" size="medium" weight={400}>
								{removeType === 'job'
									? t('jobHistory.removeJobMessage')
									: t('jobHistory.removeLicenseMessage')}
							</Body>
						</div>
						<Divider />
						<div style={{ paddingTop: '16px', width: '100%' }}>
							<Button
								size="medium"
								variant="primary"
								UNSAFE_style={{ width: '100%' }}
								onClick={
									removeType === 'job'
										? () => removeJob(removeJobIndex)
										: () => removeLicense(removeLicenseIndex)
								}>
								{t(jobHistoryRemove)}
							</Button>
						</div>
					</BottomSheet>
				)}
				<div className={style.title}>
					<Body as="p" size="large" weight={700}>
						{t('jobHistory.certificates')}
					</Body>
				</div>

				{licenseList.length !== 0
					? licenseList.map((item, index) => {
							return (
								<div style={{ margin: '16px' }} key={index}>
									<Card size="small" UNSAFE_className={style.licenseCard}>
										<div style={{ width: '100%' }}>
											<div style={{ float: 'left' }}>
												<Body as="p" size="large" weight={700}>
													{getIssuedType(item)}
												</Body>
											</div>
											<div className={isPortrait ? style.mobileEdit : style.desktopEdit}>
												<div style={{ float: 'left' }}>
													<Button
														variant="tertiary"
														UNSAFE_style={{ padding: 0, height: 'auto' }}
														onClick={() => editLicense(index)}>
														{t('jobHistory.edit')}
													</Button>
												</div>

												<div style={{ float: 'right' }}>
													<Button
														variant="tertiary"
														UNSAFE_style={{ padding: 0, height: 'auto' }}
														onClick={() => removeJobOrLicense('license', index)}>
														{t(jobHistoryRemove)}
													</Button>
												</div>
											</div>
										</div>
										<div style={{ width: '100%' }}>
											<div style={{ float: 'left' }}>
												<Body as="p" size="small" weight={700}>
													{getDescriptionDisplay(item)}
												</Body>
											</div>
										</div>
										<div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
											<div style={{ flexBasis: '50%' }}>
												<Body
													as="p"
													weight={400}
													UNSAFE_style={{ color: '#2E2F32', fontSize: '12px' }}>
													{t('jobHistory.issueDate')}
												</Body>
												<Body as="p" size="small" weight={400} UNSAFE_style={{ color: '#2E2F32' }}>
													{moment(item.certYearIssued, 'MM/YYYY', true).format('MMMM YYYY')}
												</Body>
											</div>
											<div style={{ flexBasis: '50%' }}>
												{moment(item.certYearExpires, 'MM/YYYY', true).isValid() ? (
													<>
														<Body
															as="p"
															weight={400}
															UNSAFE_style={{ color: '#2E2F32', fontSize: '12px' }}>
															{t('jobHistory.expirationDate')}
														</Body>
														<Body
															as="p"
															size="small"
															weight={400}
															UNSAFE_style={{ color: '#2E2F32' }}>
															{moment(item.certYearExpires, 'MM/YYYY', true).format('MMMM YYYY')}
														</Body>
													</>
												) : (
													''
												)}
											</div>
										</div>
									</Card>
									{!isPortrait && <Divider />}
								</div>
							);
					  })
					: ''}
				<div style={{ padding: '16px' }}>
					<Button size="medium" variant="secondary" onClick={handleAddNewLic}>
						{t('jobHistory.add')}
					</Button>
				</div>

				{!jobExp && jobList.length === 0 ? (
					<div style={{ padding: '16px' }}>
						<Checkbox
							label={t('jobHistory.checkBox')}
							checked={checkBox1}
							onChange={handleCheckbox1}
						/>
					</div>
				) : (
					<div style={{ padding: '16px' }}>
						<Checkbox
							label={t('jobHistory.checkbox1')}
							checked={checkBox2}
							onChange={handleCheckbox2}
						/>
					</div>
				)}
			</div>
			<div style={{ width: '100%' }}>
				<WizardFooter
					previousActionProps={{
						UNSAFE_className: 'wizard_footer_previous',
						onClick: () => navigate('../experience'),
						children: t('general.previous')
					}}
					nextActionProps={{
						UNSAFE_className: 'wizard_footer_next',
						disabled: disableButton(),
						onClick: () => {
							sendGtmEvent(GTM.EVENTS.APPLICATION_STEP4_EMPLOYMENT_HISTORY);
							updateHcApplicationStatus();
						},
						children: t('general.continue')
					}}></WizardFooter>
			</div>
		</div>
	);
};

export default JobHistory;
