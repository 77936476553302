import React, { useState } from 'react';
import {
	Body,
	BottomSheet,
	Button,
	Divider,
	Modal,
	Radio
} from '@walmart-web/livingdesign-components';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import style from './AboutYouPersonalInfo.module.css';

const PreEmployMentModal = (props) => {
	const { t } = useTranslation();
	const isDesktop = useMediaQuery({ minWidth: 851 });
	const isMobile = useMediaQuery({ maxWidth: 767 });
	const [rehire, setRehire] = useState({
		yes: false,
		no: false
	});

	const handleVerification = (e) => {
		const value = e.target.name;
		if (value === 'yes') {
			setRehire({ yes: true, no: false });
		} else {
			setRehire({ no: true, yes: false });
		}
	};



	return (
		<div>
			{isMobile && (
				<BottomSheet
					isOpen={props.rehireStatus}
					onClose={()=> {props.handleModal(false)}}
					title={t('aboutYouPersonalInfo.preEmployment')}>
					<div className={style.preEmployment}>
						<Body as="p" size="small" weight={400}>
							{t('aboutYouPersonalInfo.employed')}
						</Body>
						<Body as="p" size="small" weight={400}>
							{t('aboutYouPersonalInfo.previousRecord')} <br/>
							{t('aboutYouPersonalInfo.walmartLocation')}
							{props.potentialMatchStoreNbr} <br/>
							{t('aboutYouPersonalInfo.confirmRecord')} <br/>
						</Body>
						<div>
							<Radio
								label={t('aboutYouPersonalInfo.yes')}
								name="yes"
								checked={rehire.yes}
								onChange={(e) => {
									handleVerification(e);
								}}
							/>
						</div>
						<div>
							<Radio
								label={t('aboutYouPersonalInfo.no')}
								name="no"
								checked={rehire.no}
								onChange={(e) => {
									handleVerification(e);
								}}
							/>
						</div>
					</div>
					<div style={{ paddingBottom: '16px' }}>
						<Body as="p" size="small" weight={400}>
							{t('aboutYouPersonalInfo.preEmploymentText')}
						</Body>
					</div>
					<Divider />
					<div style={{ width: '100%', paddingTop: '16px' }}>
						<Button
							UNSAFE_style={{ width: '100%' }}
							variant="primary"
							onClick={() => props.handlePreEmployment(rehire)}>
							{t('aboutYouPersonalInfo.next')}
						</Button>
					</div>
				</BottomSheet>
			)}
			{isDesktop && (
				<Modal
					size="medium"
					isOpen={props.rehireStatus}
					onClose={()=> {props.handleModal(false)}}
					title={t('aboutYouPersonalInfo.preEmployment')}>
					<div className={style.preEmployment}>
						<Body as="p" size="small" weight={400}>
							{t('aboutYouPersonalInfo.employed')} <br/>
						</Body>
						<Body as="p" size="small" weight={400}>
							{t('aboutYouPersonalInfo.previousRecord')} <br/>
							{t('aboutYouPersonalInfo.walmartLocation')}
							{props.potentialMatchStoreNbr} <br/>
							{t('aboutYouPersonalInfo.confirmRecord')} <br/>
						</Body>
						<div>
							<Radio
								label={t('aboutYouPersonalInfo.yes')}
								name="yes"
								checked={rehire.yes}
								onChange={(e) => {
									handleVerification(e);
								}}
							/>
						</div>
						<div>
							<Radio
								label={t('aboutYouPersonalInfo.no')}
								name="no"
								checked={rehire.no}
								onChange={(e) => {
									handleVerification(e);
								}}
							/>
						</div>
					</div>
					<div style={{ paddingBottom: '16px' }}>
						<Body as="p" size="small" weight={400}>
							{t('aboutYouPersonalInfo.preEmploymentText')}
						</Body>
					</div>
					<Divider />
					<div style={{ width: '100%', paddingTop: '16px' }}>
						<Button
							UNSAFE_style={{ width: '100%' }}
							disabled={!rehire.yes && !rehire.no}
							variant="primary"
							onClick={() => props.handlePreEmployment(rehire)}>
							{t('aboutYouPersonalInfo.next')}
						</Button>
					</div>
				</Modal>
			)}
		</div>
	);
};

export default PreEmployMentModal;
